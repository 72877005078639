import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import media from '@gtg/utils/media-queries'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from '../styles/theme'
import GlobalWrapper from '../components/globalWrapper'

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  h1 {
    font-size: ${fontSize.f12};
    color: ${color.brand300};
    ${media.lg`
      font-size: ${fontSize.f10};
    `}
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const ContentSection = styled.section`
  flex: 1;
`

const NotFoundPage = () => {
  return (
    <GlobalWrapper>
      <Flex>
        <ContentSection>
          <Container>
            <Center>
              <Box p={5}>
                <title>Not found</title>
                <h1>404</h1>
                <p>
                  <em>Diese Seite scheint nicht zu existieren.</em>
                </p>
              </Box>
            </Center>
          </Container>
        </ContentSection>
      </Flex>
    </GlobalWrapper>
  )
}

export default NotFoundPage
